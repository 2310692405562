import React, { useEffect } from "react";
import Header from "../components/common/Header";
import Hero from "../components/common/Hero";
import Footer from "../components/common/Footer";

function PrivacyPolicy() {
    useEffect(() => {
      // Scroll to the top of the page when the component is mounted
      window.scrollTo(0, 0);
    }, []);
  return (
    <>
      <Header />
      <Hero
        title="Privacy Policy"
        heading="Privacy Policy & Data Protection"
        image={"partnership.jpg"}
      />
      <div className="section-title container my-5 pb-4 px-4">
        <h4 className="title mb-4 text-primary">Data Controller and Owner</h4>
        <p className="text-muted">
          Innovative Wellness Systems Inc. (IWS)
          <br />
          10 Beldon Rd, Dover, MA 02030
          <br />
          Contact: <a href="mailto:ted@iws4u.com">ted@iws4u.com</a>
        </p>

        <h4 className="title my-4 text-primary">Types of Data Collected</h4>
        <p className="text-muted">
          Among the types of Personal Data that this WellSole® Application
          collects, by itself or through third parties, there are: Cookies,
          Location Data, Usage Data, email address, first name and last name.
          Other Personal Data collected may be described in other sections of
          this privacy policy or by dedicated explanation text contextually with
          the Data collection.
        </p>
        <p className="text-muted">
          The Personal Data may be freely provided by the User, or collected
          automatically when using this WellSole® Application. Any use of
          Cookies - or of other tracking tools - by this WellSole® Application
          or by the owners of third-party services used by this WellSole®
          Application, unless stated otherwise, serves to identify Users and
          remember their preferences, for the sole purpose of providing the
          service required by the User. Failure to provide certain Personal Data
          may make it impossible for this WellSole® Application to provide its
          services. Users are responsible for any Personal Data of third parties
          obtained, published or shared through this WellSole Application and
          confirm that they have the third party's consent to provide the Data
          to the Owner.
        </p>

        <h4 className="title my-4 text-primary">
          Mode and Place of Processing the Data
        </h4>
        <h5 className="text-muted">Methods of Processing</h5>
        <p className="text-muted">
          The Data Controller processes the Data of Users in a proper manner and
          shall take appropriate security measures to prevent unauthorized
          access, disclosure, modification, or unauthorized destruction of the
          Data. The Data processing is carried out using computers and/or
          IT-enabled tools, following organizational procedures and modes
          strictly related to the purposes indicated. In addition to the Data
          Controller, in some cases, the Data may be accessible to certain types
          of persons in charge, involved with the operation of the WellSole®
          website and mobile app (administration, sales, marketing, legal,
          system administration) or external parties (such as third party
          technical service providers, mail carriers, hosting providers, IT
          companies, communications agencies) appointed, if necessary, as Data
          Processors by the Owner. The updated list of these parties may be
          requested from the Data Controller at any time.
        </p>

        <h5 className="text-muted">Place of Processing</h5>
        <p className="text-muted">
          The Data is processed at the Data Controller's operating offices and
          in any other places where the parties involved with the processing are
          located. For further information, please contact the Data Controller.
        </p>

        <h4 className="title my-4 text-primary">Retention Time</h4>
        <p className="text-muted">
          The Data is kept for the time necessary to provide the service
          requested by the User, or stated by the purposes outlined in this
          document, and the User can always request that the Data Controller
          suspend or remove the data.
        </p>

        <h4 className="title my-4 text-primary">Use of Collected Data</h4>
        <p className="text-muted">
          The Data concerning the User is collected to allow the Owner (the
          WellSole® application) to provide its services, as well as for the
          following purposes: Access to third party accounts, Interaction with
          external social networks and platforms, Contacting the User,
          Registration and authentication, Commercial affiliation, Handling
          payments, Displaying content from external platforms and Analytics.
        </p>
        <p className="text-muted">
          The Personal Data used for each purpose is outlined in the specific
          sections of this document.
        </p>

        <h4 className="title my-4 text-primary">
          Detailed Information on the Processing of Personal Data
        </h4>

        <h5 className="text-muted">Location Services Access</h5>
        <p className="text-muted">
          This WellSole® Application may collect, use, and share User location
          Data in order to provide location-based services. Most browsers and
          devices provide tools to opt-out from this feature by default. If
          explicit authorization has been provided, the User’s location data may
          be tracked by this WellSole® Application. WellSole® also shares user
          location data via Google Analytics in order to contextualize and
          personalize the ads of its own advertising network. Most browsers and
          devices provide options to opt out of this feature by default. If
          explicit authorization has been provided, the User’s location data may
          be tracked by this Application.
        </p>

        <h5 className="text-muted">Access to Third Party Accounts</h5>
        <p className="text-muted">
          This type of service allows this WellSole® Application to access Data
          from your account on a third party service and perform actions with
          it. These services are not activated automatically, but require
          explicit authorization by the User.
        </p>

        <h5 className="text-muted">Analytics</h5>
        <p className="text-muted">
          The services contained in this section enable the Owner (the WellSole®
          Application) to monitor and analyze web traffic and can be used to
          keep track of User behavior.
        </p>
        <p className="text-muted">
          Google Analytics (Google Inc.)
          <br />
          Google Analytics is a web analysis service provided by Google Inc.
          (“Google”). Google utilizes the Data collected to track and examine
          the use of this WellSole® Application, to prepare reports on its
          activities and share them with other Google services.
        </p>
        <p className="text-muted">
          Google may use the Data collected to contextualize and personalize the
          ads of its own advertising network.
          <ul>
            <li>Personal Data collected: Cookies and Usage Data.</li>
            <li>Place of processing: US – Privacy Policy - Opt Out</li>
          </ul>
        </p>

        <h5 className="text-muted">Commercial Affiliation</h5>
        <p className="text-muted">
          This type of service allows this Application to display advertisements
          for third-party products or services. Ads can be displayed either as
          advertising links or as banners using various kinds of graphics.
          Clicks on the icon or banner posted on the WellSole® Application are
          tracked by the third-party services listed below, and are shared with
          this Application. For details of which data are collected, please
          refer to the privacy policy of each service.
        </p>
        <p className="text-muted">
          <ul>
            <li>iTunes affiliation (Apple Inc.)</li>
            <li>
              iTunes affiliation is a commercial affiliation service provided by
              Apple Inc.
            </li>
            <li>Personal Data collected: Cookies and Usage Data.</li>
            <li>Place of processing: US – Privacy Policy</li>
          </ul>
        </p>

        <h5 className="text-muted">Contacting the User</h5>
        <p className="text-muted">
          By filling in the contact form with their Data, the User authorizes
          this WellSole® Application to use these details to reply to requests
          for information, quotes or any other kind of request as indicated by
          the form’s header.
        </p>
        <p className="text-muted">
          Personal Data collected: email address, first name and last name.
        </p>

        <h5 className="text-muted">
          Displaying Content from External Platforms
        </h5>
        <p className="text-muted">
          This type of service allows you to view content hosted on external
          platforms directly from the pages of this Application and interact
          with them. This type of service might still collect web traffic data
          for the pages where the service is installed, even when Users do not
          use it.
        </p>
      </div>
      <Footer/>
    </>
  );
}

export default PrivacyPolicy;
